import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import router from '../../router'
import store from '../../store'

import AddressModel from '../../models/address.model'

import UserEntity from '../../entities/user.entity'
import SoftwarePartnerEntity from '../../entities/software-partner.entity'
import LaboratoryEntity from '../../entities/laboratory.entity'
import OpticEntity from '../../entities/optic.entity'
import SellerEntity from '../../entities/seller.entity'
import AuthLinkEntity from '../../entities/auth-link.entity'

import RoleEnum from '../../utils/roles'
import { getErrorMessage } from '../../utils/errors'

import { ContactController } from '@/controllers/contact.controller'
import SubscriptionController from '../../controllers/subscriptions.controller'
import CheckoutSubscriptionSession from '../../entities/checkout-session-subscription.entity'
import PlanController from '@/controllers/plan.controller'
import UserController from '@/controllers/user.controller'

const subscriptionController = new SubscriptionController()

export default function () {
  const toast = useToast()
  const registerValidation = ref(null)

  const name = ref('')
  const lastName = ref('')
  const cpf = ref('')
  const email = ref('')
  const phoneNumber = ref('')
  const password = ref('')
  const isCreatingUser = ref(true)

  const address = ref(
    new AddressModel({
      line1: '',
      line2: '',
      country: '',
      number: null,
      zipCode: '',
    }),
  )

  const loading = ref(false)

  /**
   * @type {import('@vue/composition-api').Ref<SoftwarePartnerEntity | LaboratoryEntity | OpticEntity | SellerEntity>}
   */
  const relatedDoc = ref(null)

  /**
   * @type {import('@vue/composition-api').Ref<AuthLinkEntity>}
   */
  const authLink = ref(null)

  /**
   * Property that defines whether the user is subscribing to a plan.
   */
  const isSubscribingToPlan = ref(false)

  /**
   * Loads the document relation according to the URL relation
   * id.
   */
  const loadDocument = async () => {
    const id = router.currentRoute.query.al ?? ''

    if (!id) {
      showError('Operation not allowed')
      router.push('/signin')
      return
    }

    authLink.value = await store.dispatch('auth/getAuthLink', id)

    if (!authLink.value.email) {
      showError('Account already registered')
      router.push('/signin')
      return
    }

    let dispatchPath = ''
    const role = authLink.value.role

    email.value = authLink.value.email

    switch (role) {
      case RoleEnum.SOFTWARE_ADMIN:
        dispatchPath = 'software-partner/fetchOneSoftwarePartner'
        break
      case RoleEnum.LABORATORY_ADMIN:
        dispatchPath = 'laboratory/fetchOneLab'
        break
      case RoleEnum.OPTIC_ADMIN:
        dispatchPath = 'optic/fetchOneOptic'
        break
      case RoleEnum.SELLER:
        dispatchPath = 'seller/fetchOneSeller'
        break
    }

    if (
      role === RoleEnum.SOFTWARE_ADMIN ||
      role === RoleEnum.LABORATORY_ADMIN ||
      role === RoleEnum.OPTIC_ADMIN ||
      role === RoleEnum.SELLER
    ) {
      relatedDoc.value = await store.dispatch(
        dispatchPath,
        authLink.value.relationPath,
      )
    }

    if (relatedDoc.value.adminId) {
      showError('Account already registered')
      router.push('/signin')
      return
    }
  }

  /**
   * Gets a customer according to the given id.
   *
   * @param {string} id the customer id.
   * @returns the found customer.
   */
  const getCustomer = async (id) => {
    try {
      return store.dispatch('sign-up/getCustomer', id)
    } catch (e) {
      showError('Error loading customer data')
    }
  }

  const requestAccess = async (chosenRole) => {
    // sign up
    loading.value = true
    try {
      const user = new UserEntity({
        name: name.value,
        lastName: lastName.value,
        cpf: cpf.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        role: chosenRole,
        address: address.value,
      })

      const result = await store.dispatch('auth/signUp', {
        user,
        password: password.value,
      })

      if (result.error) {
        return showError(getErrorMessage(result.error))
      }

      // User created
      await store.dispatch('auth/autoSignIn')
      isCreatingUser.value = false
      return
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  const createEntityForAccessRequest = async (chosenRole, entityData) => {
    try {
      // Create entity
      let dispatchPath = ''
      entityData.adminId = store.state.auth.user.id

      switch (chosenRole) {
        case RoleEnum.SOFTWARE_ADMIN:
          dispatchPath = 'software-partner/setSoftwarePartner'
          break
        case RoleEnum.LABORATORY_ADMIN:
          dispatchPath = 'laboratory/setLaboratory'
          break
        case RoleEnum.OPTIC_ADMIN:
          dispatchPath = 'optic/setOptic'
          break
      }

      entityData.status = 'pending-authorization'

      const { relationPath } = await store.dispatch(dispatchPath, {
        id: null,
        data: entityData,
        parent: null,
      })

      // SAVE ON USER THE NEW ADMINDOCREF
      const user = store.state.auth.user
      user.adminDocRef = relationPath
      user.status = 'pending-authorization'
      await store.dispatch('auth/updateUserData', { id: user.id, data: user })

      //TO DO
      const c = new ContactController()
      let request = {
        name: '',
        fromEmail: '',
        toEmail: '',
        subject: '',
      }
    
      
      request.toEmail = email.value
      request.name = name.value
      request.subject = 'Solicitação de Acesso - Lensxpert'

      c.sendSignUpRequestEmail(request)

      const emailData = {
        company: name.value,
        toEmail: email.value,
        subject: 'Bem vindo ao Lensxpert',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendWelcomingMessageEmail(emailData)

      router.push('/admin')
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  /**
   * Signs up the user.
   */
  const signUp = async () => {
    loading.value = true
    const id = router.currentRoute.query.al ?? ''

    const isPlanParent = () => {
      if (this.userData.adminDocRef) {
        if (this.userData.adminDocRef.split('/').length > 2) return false
        else return true
      } else {
        return false
      }
    }

    const role = isSubscribingToPlan.value
      ? RoleEnum.OPTIC_ADMIN
      : authLink.value.role

    try {
      const user = new UserEntity({
        name: name.value,
        lastName: lastName.value,
        cpf: cpf.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        role,
        address: address.value,
      })

      // Save on user the path of the thing they are admins of (or if sellers)
      if (!isSubscribingToPlan.value) {
        if (
          authLink.value.role === RoleEnum.SOFTWARE_ADMIN ||
          authLink.value.role === RoleEnum.LABORATORY_ADMIN ||
          authLink.value.role === RoleEnum.OPTIC_ADMIN
        ) {
          user.adminDocRef = authLink.value.relationPath
        } else if (authLink.value.role === RoleEnum.SELLER) {
          user.sellerDocRef = authLink.value.relationPath

          const opticPath = authLink.value.relationPath.split('/sellers')[0]
          user.sellerOfOpticDocRef = opticPath
        }
      }

      if (isPlanParent) {
        user.status = 'active'
      }

      const result = await store.dispatch('auth/signUp', {
        user,
        password: password.value,
      })

      if (result.error) {
        return showError(getErrorMessage(result.error))
      }

      result.user.role = role

      // Save admin user id in the correct admin field
      if (!isSubscribingToPlan.value) {
        let dispatchPath = ''

        const userRole = authLink.value.role

        switch (userRole) {
          case RoleEnum.SOFTWARE_ADMIN:
            relatedDoc.value.adminId = result.user.id
            dispatchPath = 'software-partner/setSoftwarePartner'
            break
          case RoleEnum.LABORATORY_ADMIN:
            relatedDoc.value.adminId = result.user.id
            dispatchPath = 'laboratory/setLaboratory'
            break
          case RoleEnum.OPTIC_ADMIN:
            relatedDoc.value.adminId = result.user.id
            dispatchPath = 'optic/setOptic'
            break
          case RoleEnum.SELLER:
            relatedDoc.value.adminId = result.user.id
            dispatchPath = 'seller/setSeller'
            break
        }
        console.log('STATUS')

        //TOREVIEW
        //relatedDoc.value.status ? 'invited' : 'pending-payment'

        if (
          userRole === RoleEnum.SOFTWARE_ADMIN ||
          userRole === RoleEnum.LABORATORY_ADMIN ||
          userRole === RoleEnum.OPTIC_ADMIN ||
          userRole === RoleEnum.SELLER
        ) {
          if (isPlanParent) {
            relatedDoc.value.status = 'active'
          }
          store.dispatch(dispatchPath, {
            id: relatedDoc.value.id,
            data: relatedDoc.value,
            parent: authLink.value.relationPath,
          })
        }

        store.dispatch('auth/deleteAuthLink', id)
      }

      store.dispatch('auth/setUserId', result.user.id)
      store.dispatch('auth/setUser', result.user)
      store.dispatch(
        'auth/loadAdminDocData',
        result.user.adminDocRef || result.user.sellerDocRef,
      )

      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('toast.signup.success'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      const emailData = {
        company: result.user.name,
        toEmail: result.user.email,
        subject: 'Bem vindo ao Lensxpert',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendWelcomingMessageEmail(emailData)

      const customer = await getCustomer(result.user.id)

      if (!isSubscribingToPlan.value) {
        // Subscribe user to a pre defined given plan
        if (authLink.value.plan) {
          if (authLink.value.planType === 'stripe') {
            const sessionObj = new CheckoutSubscriptionSession({
              customer: customer.stripeId,
              lineItems: [{ price: authLink.value.plan, quantity: 1 }],
              successUrl: `${window.location.origin}/admin`,
              cancelUrl: `${window.location.origin}`,
            })

            if (authLink.value.isTrialPlan) {
              sessionObj.subscriptionData = {
                trial_period_days: authLink.value.trialDays,
              }
            }

            const sessionResult =
              await subscriptionController.subscribeToProduct(sessionObj)

            loading.value = false
            window.location.href = sessionResult.data.data.url
            return
          } else {
            // SUBSCRIBE USER TO EXTERNAL PLAN
            await new PlanController().subscribeToExternalPlan({
              userId: result.user.id,
              planId: authLink.value.plan,
              entityPath: authLink.value.relationPath,
            })
            loading.value = false
            router.push('/admin')
            return
          }
        } else {
          loading.value = false
          router.push('/admin')
          return
        }
      }

      // Buying a product
      loading.value = false
      router.push(
        `/sign-optic?uid=${result.user.id}&prid=${router.currentRoute.query.prid}`,
      )
    } catch (e) {
      console.log(e)
      showError('Error on signing up')
    } finally {
      loading.value = false
    }
  }

  /**
   * Shows an error toast.
   *
   * @param {string} message the message to be shown.
   */
  const showError = (message) => {
    toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }

  return {
    name,
    lastName,
    cpf,
    email,
    phoneNumber,
    password,
    address,

    loading,
    signUp,
    showError,
    loadDocument,

    getCustomer,
    registerValidation,
    isSubscribingToPlan,
    requestAccess,

    isCreatingUser,
    createEntityForAccessRequest,
  }
}
