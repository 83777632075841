import { CustomerController } from '../../controllers/customer.controller'

const customerController = new CustomerController()

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /**
     * Gets a customer according to the given id.
     *
     * @param {string} payload the customer id.
     * @returns the found customer entity.
     */
    getCustomer(_, payload) {
      return customerController.listenOne(payload)
    },
  },
}
