import { CustomerEntity } from '../entities/customer.entity'

import Controller from './base.controller'

const collection = 'customers'

/**
 * Class responsible for keeping all the Customer logic.
 */
export class CustomerController extends Controller {
  /**
   * Gets all customers from the database.
   *
   * @returns an array of customers.
   */
  async getAll() {
    const snapshot = await super.getAll([collection])

    return snapshot.docs.map((doc) =>
      CustomerEntity.fromFirestore({ ...doc.data(), id: doc.id }),
    )
  }

  /**
   * Gets a customer according to its id.
   *
   * @param {string} id the customer id.
   * @returns a customer object.
   */
  async getOne(id) {
    const doc = await super.getById([collection, id])

    return CustomerEntity.fromFirestore({ ...doc.data(), id: doc.id })
  }

  /**
   * Listens for changes in a certain document according to the given id.
   *
   * @param {string} id the customer document id.
   * @returns {Promise<CustomerEntity>} a Promise that resolves with the customer entity.
   */
  async listenOne(id) {
    return new Promise(
      (res, rej) => {
        try {
          super.listen([collection, id], (document) => {
            if (!document.exists()) {
              return
            }

            res(
              CustomerEntity.fromFirestore({
                ...document.data(),
                id: document.id,
              }),
            )
          })
        } catch (e) {
          rej(e)
        }
      },
      (error) => rej(error),
    )
  }

  /**
   * Deletes a customer from the database.
   *
   * @param {string} id the customer id.
   * @throws a Firebase exception.
   */
  async delete(id) {
    await super.delete(`${collection}/${id}`)
  }
}
