<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-row class="auth-inner py-2" align-h="center">
      <b-card>
        <b-col cols="12">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t('contractForm.head1') }}
          </b-card-title>

          <b-card-text class="mb-2">
            {{ $t('contractForm.body1') }}
          </b-card-text>

          <!-- user form -->
          <validation-observer ref="registerValidation" #default="{ invalid }">
            <b-form class="auth-form mt-2" @submit.prevent>
              <b-row>
                <b-col cols="12" md="6" lg="6" sm="6">
                  <!-- name -->
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('contractForm.name')"
                      label-for="name"
                    >
                      <b-form-input
                        v-model="name"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length }"
                        :state="errors.length ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col>
                  <!-- last name -->
                  <validation-provider
                    #default="{ errors }"
                    name="last name"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('contractForm.last')"
                      label-for="lastName"
                    >
                      <b-form-input
                        v-model="lastName"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length }"
                        :state="errors.length ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('contractForm.cpf')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('contractForm.cpf')"
                      label-for="CPF"
                    >
                      <cleave
                        v-model="cpf"
                        class="form-control"
                        :options="cpfDelimiter"
                        :class="{ 'is-invalid': errors.length }"
                        :state="errors.length ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    :rules="'email' + (isSubscribingToPlan ? '|required' : '')"
                  >
                    <b-form-group
                      :label="$t('contractForm.email')"
                      label-for="email"
                    >
                      <b-form-input
                        :disabled="!isSubscribingToPlan"
                        v-model="userEmail"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length }"
                        :state="errors.length ? false : null"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- password -->
              <b-form-group
                :label="$t('contractForm.password')"
                label-for="register-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'is-invalid': errors.length }"
                  >
                    <b-form-input
                      v-model="password"
                      id="register-password"
                      class="form-control-merge"
                      name="register-password"
                      :type="passwordFieldType"
                      :state="errors.length ? false : null"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                :label="$t('contractForm.cPassword')"
                label-for="register-confirm-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="confirm password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="{ 'is-invalid': errors.length }"
                  >
                    <b-form-input
                      v-model="confirmPassword"
                      id="register-confirm-password"
                      class="form-control-merge"
                      name="register-confirm-password"
                      :type="showConfirmPw ? 'text' : 'password'"
                      :state="errors.length ? false : null"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="!showConfirmPw ? 'EyeIcon' : 'EyeOffIcon'"
                        @click="showConfirmPw = !showConfirmPw"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                block
                type="submit"
                :disabled="!valid || invalid"
                :variant="valid && !invalid ? 'primary' : 'secondary'"
                @click="signUp()"
              >
                <b-spinner v-if="loading" small label="Loading" />

                <span v-else>{{ $t('contractForm.signup') }}</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-card>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BCard,
  BCardTitle,
  BCardText,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormDatepicker,
  BLink,
  BInputGroupAppend,
  BRow,
  BSpinner,
} from 'bootstrap-vue'

import { computed, ref, onUnmounted } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'

import { required, integer, positive, email, numeric } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import { cpfDelimiter } from '@/utils/formatters'

import store from '../../store'
import router from '../../router'

import useSignUp from './useSignUp'
import signUpStoreModule from './signUpStoreModule'
import planStoreModule from '../admin/plans/planStoreModule'

import softwarePartnerStoreModule from '../sections/software-partners/softwarePartnerStoreModule'
import labStoreModule from '../sections/labs/labStoreModule'
import opticStoreModule from '../sections/optics/opticStoreModule'
import sellerStoreModule from '../sections/sellers/sellerStoreModule'

export default {
  name: 'SignUp',

  components: {
    BCard,
    BCardTitle,
    BCardText,
    BCol,
    BRow,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
    BInputGroup,
    BLink,
    BSpinner,
    Cleave,

    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      required,
      integer,
      positive,
      email,
      numeric,
      cpfDelimiter,

      showConfirmPw: false,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  setup() {
    const SOFTWARE_PARTNER_APP_STORE_MODULE_NAME = 'software-partner'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const SELLER_APP_STORE_MODULE_NAME = 'seller'

    const SIGN_UP_APP_STORE_MODULE_NAME = 'sign-up'
    const PLAN_APP_STORE_MODULE_NAME = 'plan'

    if (!store.hasModule(SIGN_UP_APP_STORE_MODULE_NAME)) {
      store.registerModule(SIGN_UP_APP_STORE_MODULE_NAME, signUpStoreModule)
    }

    if (!store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        SOFTWARE_PARTNER_APP_STORE_MODULE_NAME,
        softwarePartnerStoreModule,
      )
    }

    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }

    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }

    if (!store.hasModule(SELLER_APP_STORE_MODULE_NAME)) {
      store.registerModule(SELLER_APP_STORE_MODULE_NAME, sellerStoreModule)
    }

    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) {
      store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(SIGN_UP_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SIGN_UP_APP_STORE_MODULE_NAME)

      store.hasModule(PLAN_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)

      store.hasModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SOFTWARE_PARTNER_APP_STORE_MODULE_NAME)

      store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME)

      store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME)

      store.hasModule(SELLER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SELLER_APP_STORE_MODULE_NAME)
    })

    const {
      name,
      lastName,
      email,
      phoneNumber,
      cpf,
      city,
      password,
      address,
      loading,
      registerValidation,
      signUp,
      showError,
      loadDocument,
      isSubscribingToPlan,
    } = useSignUp()

    const validateOobCode = async () => {
      const oobCode = router.currentRoute.query.oobCode
      const result = await store.dispatch('auth/verifyOobCode', oobCode)

      if (['auth/invalid-req-type', 'error'].includes(result)) {
        showError('Invalid link')
        router.push('/login')
        return
      }

      loadDocument()
    }

    const init = async () => {
      const priceId = router.currentRoute.query.prid
      const planId = router.currentRoute.query.plid

      if (!priceId || !planId) {
        return validateOobCode()
      }

      const plan = await store.dispatch('plan/fetchOnePlan', planId)
      if (planId == 0) {
        return
      } else {
        if (
          !plan ||
          !plan.active ||
          !plan.prices.find((p) => p.id === priceId)
        ) {
          showError('Invalid product/plan')
          router.push('/login')
          return
        }
      }

      isSubscribingToPlan.value = true
    }
    init()

    const confirmPassword = ref('')

    const valid = computed({
      get: () => password.value && password.value === confirmPassword.value,
    })

    return {
      name,
      lastName,
      cpf,
      userEmail: email,
      phoneNumber,
      city,
      password,
      confirmPassword,
      address,

      loading,
      signUp,

      valid,
      registerValidation,
      isSubscribingToPlan,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
