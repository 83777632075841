var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('b-row',{staticClass:"auth-inner py-2",attrs:{"align-h":"center"}},[_c('b-card',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('contractForm.head1'))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('contractForm.body1'))+" ")]),_c('validation-observer',{ref:"registerValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contractForm.name'),"label-for":"name"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"state":errors.length ? false : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contractForm.last'),"label-for":"lastName"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"state":errors.length ? false : null},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contractForm.cpf'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contractForm.cpf'),"label-for":"CPF"}},[_c('cleave',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"options":_vm.cpfDelimiter,"state":errors.length ? false : null},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":'email' + (_vm.isSubscribingToPlan ? '|required' : '')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contractForm.email'),"label-for":"email"}},[_c('b-form-input',{staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"disabled":!_vm.isSubscribingToPlan,"state":errors.length ? false : null},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('contractForm.password'),"label-for":"register-password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{ 'is-invalid': errors.length }},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","name":"register-password","type":_vm.passwordFieldType,"state":errors.length ? false : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('contractForm.cPassword'),"label-for":"register-confirm-password"}},[_c('validation-provider',{attrs:{"name":"confirm password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{ 'is-invalid': errors.length }},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-confirm-password","name":"register-confirm-password","type":_vm.showConfirmPw ? 'text' : 'password',"state":errors.length ? false : null},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":!_vm.showConfirmPw ? 'EyeIcon' : 'EyeOffIcon'},on:{"click":function($event){_vm.showConfirmPw = !_vm.showConfirmPw}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"block":"","type":"submit","disabled":!_vm.valid || invalid,"variant":_vm.valid && !invalid ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.signUp()}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":"","label":"Loading"}}):_c('span',[_vm._v(_vm._s(_vm.$t('contractForm.signup')))])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }