/**
 * Formats the CPF to the XXX.XXX.XXX-XX pattern.
 *
 * @param {string} cpf the CPF to be formatted.
 * @returns the formatted CPF.
 */
export function cpf(cpf) {
  let formatted = ''

  const len = cpf.length > 11 ? 11 : cpf.length

  for (let i = 0; i < len; i++) {
    if (i === 3 || i === 6) {
      formatted += '.'
    }

    if (i === 9) {
      formatted += '-'
    }

    formatted += cpf[i]
  }

  return formatted
}

/**
 * Formats the CNPJ to the XX.XXX.XXX/XXXX-XX pattern.
 *
 * @param {string} cnpj the CNPJ to be formatted.
 * @returns the formatted CNPJ.
 */
export function cnpj(cnpj) {
  let formatted = ''

  const len = cnpj.length > 14 ? 14 : cnpj.length

  for (let i = 0; i < len; i++) {
    if (i === 2 || i === 5) {
      formatted += '.'
    }

    if (i === 8) {
      formatted += '/'
    }

    if (i === 12) {
      formatted += '-'
    }

    formatted += cnpj[i]
  }

  return formatted
}

/**
 * CPF delimiter to be used as an input mask.
 */
export const cpfDelimiter = {
  delimiters: ['.', '.', '-'],
  blocks: [3, 3, 3, 2],
}

/**
 * CNPJ delimiter to be used as an input mask.
 */
export const cnpjDelimiter = {
  delimiters: ['.', '.', '/', '-'],
  blocks: [2, 3, 3, 4, 2],
}
